<template>
    <div @click="$emit('venueSelected', this.venueTUID)" class="wrapper-todiv" style="cursor:pointer;">
        <div class="userinfoframe" style="height: 9vh">
            <table align=left style="height:85%;" border=0 width=100%>
            <thead>
            <tr>
                <!-- ref font size 0 below, weird hack required see https://stackoverflow.com/questions/10500249/perfect-vertical-image-alignment -->
                <td align=left width=20% style="height: 8vh; font-size: 0; text-align: center"><img border=0 :v-if="venueImageData!==undefined" :src="venueImageDataComputed" style="height: 7vh"></td>
                <td >
                <!-- nested table -->
                <table border=0 width=100%>
                <thead>
                    <tr>
                        <td class="username" style="font-size: 17px; text-align: left">{{ this.venueName }}</td> 
                    </tr>
                </thead>
                </table>
                </td>
                
            </tr>
            </thead>
            </table>
        </div>
        <p></p>
    </div>
</template>

<script>
export default {
    name: 'Venue',
    props:  {
        venueTUID: String,
        venueId: String,
        venueName: String,
        venueImageData: String
    },
    computed: {
        venueImageDataComputed() {

            // /console.log("Let's compute image");
            if (this.venueImageData) {
                // // /console.log("YEA:: " + this.recipientImageData);
                return 'data:image/png;base64,' + this.venueImageData;
            } else {
                // // /console.log("NAY");
                // return require('@/assets/tulogoapp.png');
                // this was the syntax for plain old img src, but since it is now bound :src, the above is the syntax  "../assets/tulogoapp.png";
            }
        } 
    },
    methods: {
        doBibble(){
            // /console.log("Bibble");
        }
    }
}
</script>

<style scoped>


</style>