<template>
  <!-- showVenuesList --><div class="wrapper-todiv" style="margin: auto; height: 120vh; outline:0.0em solid green; ">
    <div class="wrapper-todiv" style="position: absolute; top: 10px; outline:0.0em solid pink; ">
      <div class="primarytextmidcentre" style="width: 100%"><span class display:center>Tap the place where the person you want to tip works</span></div>
      <div class="centreverticaldivsearch"><input ref="searchboxtws" v-on:keyup="searchinput()" class="searchinput" type="text" placeholder="Search.." :style="{ backgroundImage: 'url(' + require('@/assets/search50.png') + ')' }"></div>
      <div :key="venue.TUID" v-for="venue in venuesArrayObjectFiltered">
        <Venue :venueId=venue.objectId  :venueName=venue.fullName :venueTUID=venue.TUID @venueSelected="doVenueSelected"/>
      </div>
    </div>
  </div>
</template>

<script>

import Venue from '../components/Venue.vue';
const Parse = require('parse/node');
import shared from '../shared.js';

export default {
  inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
  components: {
    Venue,
  },
  data(){
    return {
      venue: {venueName: ""}, // initial value to avoid UI console errors though seemed to work
      venuesArrayObject: undefined,
      venuesArrayObjectFiltered: undefined,
      venueImageData: undefined,
      venueImageDataURL: undefined,
      venueData: undefined,
      usedSearchAtLeastOnce: false,
      groupName: "",
    }
  },
  methods: {
    searchinput(){
      this.venuesArrayObjectFiltered = this.venuesArrayObject.filter(item => item.fullName.toLowerCase().includes(this.$refs.searchboxtws.value.toLowerCase()));
      if (this.usedSearchAtLeastOnce === false){
        shared.saveToUserPath(this.devEnv, "Used Venue Search At Least Once " + new Date().toISOString(), this.globalPushForwardInterval);
        this.usedSearchAtLeastOnce = true;
      }
    },
    doVenueSelected(venueSelected){

      shared.saveToUserPath(this.devEnv, "Selected Venue: " + venueSelected + " " + new Date().toISOString(), this.globalPushForwardInterval);
      
      window.localStorage.setItem("tutws", true);

      // /console.log("grubble::: " + JSON.stringify(venueSelected, null, 2));
      //this.$router.back();

      let returnJSON = {
        selectedTUID: venueSelected
      };

      this.$emit('return-json', returnJSON);
    },
    doShowHome(){
      // /console.log("we're off!");

      let returnJSON = { showHome: true }; 
      this.$emit('return-json', returnJSON);

    },
    getVenueParams(){

      var params = {};
      // params["location"] = userLocation;

      return params;
    },
    doSetMetas(){

      var venuesKeywordsStr = "";

      // /console.log("this.venuesArrayObject::: " + JSON.stringify(this.venuesArrayObject));

      for (let key of Object.keys(this.venuesArrayObject)) {
        venuesKeywordsStr += "," + this.venuesArrayObject[key].fullName;
      }

       document.title = "Tip even if you're at home - with thankU cashless tipping";
       document
      .getElementsByTagName('meta')
      .namedItem('description')
      .setAttribute('content',"Tip even if you're at home - with thankU cashless tipping. Normally you tip by scanning the thankU QR badge at the place you're tipping but if the person you want to tip works at a business in this list you can tip them by tapping the business name, even if you personally aren't present there. Tip without needing to scan a thankU QR code at " + venuesKeywordsStr) ;
      
      var keywords = document.getElementsByTagName('meta').namedItem('keywords').getAttribute('content',"");

      document
      .getElementsByTagName('meta')
      .namedItem('keywords')
      .setAttribute('content', keywords + venuesKeywordsStr);  
    }
    
  }, 
  async created(){

      document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
      // /console.log("Tip without scanning created");
      Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);
      var params = this.getVenueParams();


      // const fromScreen = this.$route.params !== undefined ? " from " + this.$route.params.fromScreen : this.fromScreen !== undefined ? this.fromScreen : "";
      // shared.sendAppAlert("Great! Someone tapped Tip Without Scanning button" + fromScreen + "!", undefined, this.appId, this.appJSKey);
      
      try {
        // /console.log("timestamp PRE " + new Date().toISOString());

        
        const VenueData = await Parse.Cloud.run("getVenuesArray", params);  
        // takes about ### on laptop running local server (shouldn't make a difference)
        // /console.log("timestamp POST " + new Date().toISOString());
        // /console.log(JSON.stringify(VenueData, null, 2));
        this.venueData = VenueData;   
        this.venuesArrayObject = this.venueData;   
        this.venuesArrayObjectFiltered = this.venuesArrayObject;
        this.doSetMetas();

        
        this.groupName = (this.$route.params !== undefined && this.$route.params.groupName !== undefined) ? this.$route.params.groupName : "";

        if (this.groupName !== ""){
          this.$refs.searchboxtws.value = this.groupName;      
          this.venuesArrayObjectFiltered = this.venuesArrayObject.filter(item => item.fullName.toLowerCase().includes(this.$refs.searchboxtws.value.toLowerCase()));
        }
        

      } catch (e) {
        // /console.log("BROWSER get Venue Data FAILED");
        console.error(e);
        return (e);
      } 

      let viewport = document.querySelector("meta[name=viewport]");
      viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0');

      // shared.saveToUserPath(this.devEnv, "Tip without scanning " + new Date().toISOString(), this.globalPushForwardInterval);
    }, 
    mounted(){
      
        // console.log("about to nexttick");

        if (shared.getDeviceFullObject().deviceType === "Desktop"){
          // on a phone it's i) pointless ii) counterproductive because it initiates pushing half the screen up to be invisible
          this.$nextTick(() => {
            // console.log("JUST DID nexttick");
            this.$refs['searchboxtws'].focus(); // ref virtual DOM see https://developer.mozilla.org/en-US/docs/Learn/Tools_and_testing/Client-side_JavaScript_frameworks/Vue_refs_focus_management
            window.scrollTo({ top: 0, behavior: 'auto' }); // or 'smooth'
          });
        }
    }
}
</script>

